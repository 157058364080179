<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{headerType}} {{pageTitle}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="brnadForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="initValues"
        >

          <!-- Medical Rep -->
          <validation-provider
                #default="validationContext"
                name="medicalRep"
                rules="required"

            >
              <b-form-group
              label="Select Medical Rep"
              label-for="medicalRep"
              >
                  <v-select
                      id="medicalRep"
                      v-model="medical_rep_id"
                      :state="getValidationState(validationContext)"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="medicalReps"
                      :reduce="types => types.id"
                      select
                      class="form-control-merge"
                      @input="getHospitalsByRegion"

                  />
                  <div v-if="validationContext.errors[0]" class="invalid-feedback">{{ validationContext.errors[0] }}</div>
              <b-form-invalid-feedback :force-show="!medical_rep_id">
                  {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.medical_rep_id">{{ allerrors.medical_rep_id[0] }}</span>
              </b-form-group>
          </validation-provider>
    
        
         <!-- date -->
         <validation-provider
              #default="validationContext"
              name=" Date"
              rules="required"
          >
              <b-form-group
              label="Date"
              label-for="date"
              >
              <flat-pickr
                  id="date"
                  v-model="date"
                  class="form-control"
                  :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback :force-show="!date">
                  {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.date">{{ allerrors.date[0] }}</span>
              </b-form-group>
          </validation-provider>


          <!-- Hospitals -->
            <validation-provider
                #default="validationContext"
                name="hospital"
                rules="required"
            >
                <b-form-group
                label-for="hospital"
                label="Select Hospital"
                >
                  <v-select
                      id="hospital"
                      v-model="hospital_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="hospitalsByRegion"
                      :reduce="type => type.id"
                      :state="getValidationState(validationContext)"
                      select
                      @input="getDoctors(hospital_id)"
                      placeholder="Hospitals"
                  />
                <b-form-invalid-feedback :force-show="!hospital_id" >
                    {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <span class="text-danger text-sm" v-if="allerrors.hospital_id">{{ allerrors.hospital_id[0] }}</span>
                </b-form-group>
            </validation-provider>
  
  
            <!-- Doctors -->
                <validation-provider
                    #default="validationContext"
                    name="doctor_id"
                    rules="required"
                >
                    <b-form-group
                    label-for="doctor"
                    label="Select Doctor">
                        <v-select
                            id="doctor_id"
                            v-model="doctor_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="doctors"
                            :reduce="types => types.doctor_id"
                            :state="getValidationState(validationContext)"
                            select
                            placeholder="Doctors"
                        />
                    <b-form-invalid-feedback :force-show="!doctor_id" >
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    <span class="text-danger text-sm" v-if="allerrors.doctor_id">{{ allerrors.doctor_id[0] }}</span>
                    </b-form-group>
                </validation-provider>
  
  
            <!-- visit type -->
                <validation-provider
                    #default="validationContext"
                    name="visit_type_id"
                    rules="required"
                >
                    <b-form-group
                    label-for="slug"
                    label="Select Visit Type"
                    >
                        <v-select
                            id="visit_type_id"
                            v-model="visit_type_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="visitTypes"
                            :reduce="types => types.id"
                            :state="getValidationState(validationContext)"
                            select
                            placeholder="Visit Type"
                        />
                    <b-form-invalid-feedback :force-show="!visit_type_id">
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    <span class="text-danger text-sm" v-if="allerrors.visit_type_id">{{ allerrors.visit_type_id[0] }}</span>
                    </b-form-group>
                </validation-provider>



          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!loading"
            >
              {{ headerType }} 
            </b-button>
            <!-- spinner -->
            <b-button
              v-if="loading"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner small />
              Loading...
            </b-button>
            <!-- end spinner -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormFile, BCardText, BMedia, BAvatar, BSpinner,  BRow, BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onMounted, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { watch } from '@vue/composition-api'
import DataService from './data/services'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ThumbsDownIcon } from 'vue-feather-icons'
import flatPickr from 'vue-flatpickr-component'

export default {
  
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    BSpinner,
    vSelect,
    BRow, 
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataSlider: {
      type: Object,
      default: () => {},
    },
    companyType: {
      type: [Array, Object],
      required: true
    },
    pageTitle: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      required,
      alphaNum,
      email,
      allerrors: [],
      headerType: 'Add',
      medical_rep_id:'',
      hospital_id: '',
      visit_type_id: '',
      doctor_id: '',
      date: '',
      id: '',
      hospitals: [],
      visitTypes: [],
      hospitalsByRegion: [],
      doctors: [],
      nextTodoId: 2,
    }
  },
  watch: {
    isAddNewUserSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.dataSlider).length === 0) {
        this.headerType = 'Add'
        this.initValues()
      }else {        
        this.id = this.dataSlider.id
        this.medical_rep_id = this.dataSlider.medical_rep_id
        this.hospital_id = this.dataSlider.hospital_id
        this.doctor_id = this.dataSlider.doctor_id
        this.visit_type_id = this.dataSlider.visit_type_id
        this.date = this.dataSlider.date
        this.headerType = 'Edit'
        this.getHospitalsByRegion(this.dataSlider.medical_rep_id)
        this.getDoctors(this.dataSlider.hospital_id)
      }
    }
  },
  methods: {
    initValues() {
      if(this.dataSlider.id) return
        this.id = null
        this.medical_rep_id = null
        this.hospital_id = null
        this.doctor_id = null
        this.date = null
        this.visit_type_id = null
        this.allerrors = {}
    },
    onSubmit(){
      if(!this.id){
        this.sendData()
      }else{
        this.editData();
      }
    },
    prepareData(){
      return {
        medical_rep_id: this.medical_rep_id,
        hospital_id: this.hospital_id,
        doctor_id: this.doctor_id,
        visit_type_id: this.visit_type_id,
        date: this.date
      }
    },
    sendData(){
      this.loading = true;
        DataService.create(this.prepareData())
        .then(response => {
          this.loading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            this.$parent.refreshData(response.data.data);
            // this.$emit('refreshData('+response.data.data+')');
            
            this.$emit('update:is-add-new-user-sidebar-active', false)
          }else if(responseCode === 101){
            this.allerrors = response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
          else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
    editData(){
      this.loading = true;

        DataService.update(this.id, this.prepareData())
        .then(response => {
          this.loading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            this.$parent.refreshData(response.data.data);
            // this.$emit('refreshData('+response.data.data+')');
            
            this.$emit('update:is-add-new-user-sidebar-active', false)
          }else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    repareType(type){
      let list=[];
      type.forEach(function(value, key) {
        list.push(value.type);
      });
      return list
    },
    getHospitals(){
        this.isLoading = true;
        DataService.getHospitals()
        .then(response => {
          this.isLoading = false
          this.hospitals = response.data.data
        })
    },
    getVisitType(){
        this.isLoading = true;
        DataService.getvisitType()
        .then(response => {
          this.isLoading = false
          this.visitTypes = response.data.data
        })
    },

    getDoctors(val){
      console.log(val);
      this.doctors = []
      Object.entries(this.hospitals).forEach(([key, value]) => {
        if(val == value.id){
          this.doctors = value.doctor_with_specialty
        }
      });
    },

    getHospitalsByRegion(val){ 
      this.hospitalsByRegion = []
      Object.entries(this.medicalReps).forEach(([key, value]) => {
        if(val == value.id){
          this.prepareHospitalsByRegion(value.region_id)
        }
      });
    },
    prepareHospitalsByRegion(regionId){
      Object.entries(this.hospitals).forEach(([key, value]) => {
        if(regionId == value.region_id){
          this.hospitalsByRegion.push(value)
        }
      });
    }
    
  },
  mounted() {
    this.getHospitals()
    this.getVisitType()
  },
  computed: {
    medicalReps (){
      return this.$store.getters['admin/GetMedicalReps'] 
    },
  },
  setup() {

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()
    return {
      // userData,
      // onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      //  ? Demo - Update Image on click of update button

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>