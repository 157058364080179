<template>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Date</label>
              <flat-pickr
                v-model="date"
                class="form-control"
                @input="(val) => dateChange(val)"
              />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label> MedicalRep </label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="medicalRepOptions"
              label="name"
              class="w-100"
              :reduce="val => val.id"
              @input="(val) => regionChange(val)"
              v-model="medicalRepId"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Status</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              label="name"
              class="w-100"
              :reduce="val => val.id"
              @input="(val) => statusChange(val)"
              v-model="status"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BFormGroup
  } from 'bootstrap-vue'
  import flatPickr from 'vue-flatpickr-component'
  import M from 'minimatch';
  import vSelect from 'vue-select'
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardBody,
      vSelect,
      BFormInput,
      BFormGroup,
      flatPickr,
    },
    computed: {
        medicalRepOptions (){
          return this.$store.getters['admin/GetMedicalReps'] 
        }
    },
    data() {
        return {
            name: '',
            date: '',
            status: '',
            medicalRepId: '',
            statusOptions : [
                { name: 'incompleted', id: 'incompleted' },
                { name: 'completed', id: 'completed' },
            ],
        }
    },
    methods: {
        regionChange(val){
            this.medicalRepId = val
            this.refreshDatas()
        },
        statusChange(val) {
            this.status = val
            this.refreshDatas()
        },
        dateChange(val) {
            this.date = val
            this.refreshDatas()
        },
        refreshDatas(){
            this.$parent.refreshData({date: this.date, medicalRepId: this.medicalRepId, status: this.status});
        }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  </style>
  