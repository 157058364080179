import axios from '@/libs/axios'

class DataService {
  getAll(params, page) {
    return axios.get(`/visits?page=${page}&${params}`);
  }
  get(id) {
    return axios.get(`/visits/${id}`);
  }
  create(data) {
    return axios.post("/storeVisit", data);
  }
  update(id, data) {
    return axios.put(`/visits/${id}`, data);
  }
  getHospitals() {
    return axios.get(`/hospitalsLkb`);
  }

  getvisitType () {
    return  axios.get(`/lkbVisitType`);
  }
}
export default new DataService();