<template>
<div>

  <!-- Filters -->
  <hospital-filter
    @refreshData="refreshData"
  />

<b-card>


    

    <add-new
      :is-add-new-user-sidebar-active.sync="isAddNewSidebarActive" :companyType="companyType" :pageTitle="pageTitle"   :dataSlider="sidebarData"  
    />

    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
     <b-button
        @click="addNewData"
        variant="outline-primary"
        class="mb-2"
        style="width: 170px;"
      >
        Add {{ pageTitle}}
      </b-button>
      

      <b-table   responsive :items="items.data" :fields="tableColumns" class="mb-0"
      :no-border-collapse="true">

      <template #cell(id)="data">
          {{data.index + 1}}
      </template>

      <template #cell(created_at)="data" >
          {{data.value | moment('YY-MM-DD HH:mm:ss')}}
      </template>

      <template #cell(created_by)="data" >
        {{(data.value)? data.item.medical_rep.name : data.item.admin.name }}
    </template>

      <template #cell(report_count)="data">
        <b-badge :variant="(data.value == 0) ? 'light-danger' : 'light-success'" style="cursor: pointer;">
          {{ data.value }}
        </b-badge>
      </template>


      <template #cell(show_details)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails"
        >
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
            </span>
          </span>
          <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
        </b-form-checkbox>
      </template>

      <template #row-details="row">
        <child-table 
          :childs="row.item.report"  />
      </template>

      

      <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editData(data.item)" >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      
      <div class="demo-spacing-0">
        <b-pagination
          :value="1"
          
          :total-rows="items.total"
          :per-page="items.per_page"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @input="(value)=>getData(value)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>


    </b-overlay>
  </b-card>

</div>
</template>


<script>
import { BTable, BCard, BCardText,BAvatar, BOverlay, BBadge, BFormSelect, BDropdownItem, BDropdown, BButton, BPagination,BFormCheckbox, BRow, BCol, } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddNew from './AddNew.vue'
// import companyData from './companyData'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { onMounted } from 'vue'
import DataService  from './data/services'
import HospitalFilter from './filter'
import ChildTable from './child.vue'
export default {
  
  components: {
    BTable,
    BCard,
    BCardText,
    BAvatar,
    BOverlay,
    BBadge,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    AddNew,
    BButton,
    ToastificationContent,
    HospitalFilter,
    BPagination,
    ChildTable,
    BFormCheckbox, 
    BRow, 
    BCol,
  },
  data() {
    return {
      items: [],
      sidebarData: {},
      isAddNewSidebarActive: false,
      isLoading: true,
      companyType: [],
      pageTitle: "Visit",
      params:'',
      currentPage: 1,
      tableColumns: [
        { key: 'actions' },
        { key: 'show_details', label: 'show'},
        { key: 'id', sortable: true },
        { key: 'date', label: 'Date', sortable: true },
        { key: 'medical_rep.name', label: 'MedicalRep', sortable: true },
        { key: 'hospital.name', label: 'Hospital', sortable: true },
        { key: 'doctor.name', label: 'Doctor', sortable: true },
        { key: 'type.name', label: 'Type', sortable: true },
        { key: 'report_count', label: 'Report', sortable: true},

        { key: 'created_by', label: 'Created by', sortable: true },
        // { key: 'status', label: 'Status', sortable: true },
        { key: 'created_at', label: 'Created At', sortable: true },
        
      ],
      status: [
        {
          1: 'Actvie', 0: 'Inactive',
        },
        {
          1: 'light-success', 0: 'light-danger', 
        }
      ],
      nameFilter: '',
      
    }
  },

    
  methods: {
    getData(page){
      this.isLoading = true;
      DataService.getAll(this.params, page)
      .then(response => {
        this.isLoading = false
        this.items = response.data.data
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
        })
    },
    changeStatus(id, status){
      this.isLoading = true;
      DataService.update(id, {status: !status})
      .then(response => {
        this.isLoading = false
        this.items = response.data.data
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
      })
    },
    addNewData() {
      this.$router.push('/createVisit') 
      // this.sidebarData = {}
      // this.toggleDataSidebar(true)
    },
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val=false) {
      this.isAddNewSidebarActive = val
    },
    refreshData(data){
      console.log(data);
      this.params = `date=${(data.date)? data.date : ''}&mediaclRepId=${(data.medicalRepId)? data.medicalRepId : ''}&status=${(data.status)? data.status : ''}`
      this.getData(this.currentPage)
    },
    
  },
  mounted() {
    this.getData(this.currentPage)
  },
  
}
</script>